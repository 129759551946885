export enum FilterKeyForReferenceData {
    DailyReport = 'DailyReport',
    LeaseOperator = 'LeaseOperatorReport',
    Select = 'Select',
    HourlyReport = 'HourlyReport',
    CustomerReport = 'CustomerReport',
    RejectedLoads = 'RejectedLoads',
    ScheduledReports ='ScheduledReports',
    UpcomingCertificationReport = 'UpcomingCertificationReport',
    SearchTickets = 'SearchTickets',
    MasterTicketReport = 'MasterTicketReport'
}
export enum ReportMessage {
    DAILY_ERROR_MESSAGE = 'Daily Report data could not be loaded',
    DAILY_NO_RECORDS = 'No Records for selected Status',
    MASTER_TYPE_INFO = 'No Records for selected Ticket Type',
    NO_RECORDS = 'No Records Found',
    UPLOAD_ERROR = 'Error Sending Report',
    LEASE_ERROR_MESSAGE = 'Carrier Company Report data could not be loaded',
    HOURLY_ERROR_MESSAGE = 'Hourly Report data could not be loaded',
    CUSTOMER_ERROR_MESSAGE = 'Customer Report data could not be loaded',
    REJECTED_ERROR_MESSAGE = 'Rejected Load data could not be loaded',
    SCHEDULED_ERROR_MESSAGE = 'Scheduled Reports data could not be loaded',
    UPCOMINGCERT_ERROR_MESSAGE = 'Upcoming Certifications Report data could not be loaded',
    MASTERTICKET_ERROR_MESSAGE = 'Master Ticket Report data could not be loaded',
    DISPATCHDETAILS_ERROR_MESSAGE = 'Dispatch Details data could not be loaded',
    SAVE = 'Saved Successfully',
    FAILED_SUBMIT = 'Failed to save changes'
}

export enum ReportsButton {
    PDF = 'Export to PDF',
    CSV = 'Export to Excel',
    PREV = '<<',
    NEXT = '>>'
}

export enum DailyRecordHeaderName {
    TICKETDATE = 'TICKET CREATED DATE/ TICKET PLANNED DATE',
    TICKETACCEPTEDDATE = 'TICKET ACCEPTED DATE',
    TICKETNUMBER = 'TICKET NUMBER',
    DRIVERNAME = 'DRIVER NAME',
    POWERUNIT = 'POWER UNIT IDENTIFIER',
    PRODUCT = 'PRODUCT',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    ACTUALVOL = 'ACTUAL VOLUME',
    ESTIMATEVOL = 'ESTIMATED VOLUME',
    STATUS = 'STATUS',
    LOADDATE = 'LOAD DATE',
    CARRIER = 'CARRIER COMPANY',
    TRAILER = 'TRAILER',
    COMPLETEDDATETIME = 'COMPLETED DATE TIME',
    LOADDATETIME = 'LOAD DATE TIME',
    REGION = 'REGION'
}
export enum DailyRecordFieldName {
    TICKETDATE = 'TicketDate',
    TICKETNUMBER = 'TicketNumber',
    DRIVERNAME = 'Driver_Name',
    POWERUNIT = 'Power_Unit_Identifier',
    PRODUCT = 'Product',
    CONSIGNOR = 'Consignor',
    CONSIGNEE = 'Consignee',
    ACTUALVOL = 'Actual_Vol',
    ESTIMATEVOL = 'Estimated_Vol',
    STATUS = 'Status',
    LOADDATE = 'Load_Date',
    CARRIER = 'CompanyName',
    TRAILER = 'Trailer',
    COMPLETEDDATETIME = 'CompletedDate',
    LOADDATETIME = 'Load_Date',
    REGION = 'Region',
    TICKETACCEPTEDDATE = 'AcceptedDate'
}

export enum HourlyReportHeaderName {
    TICKETDATE = 'TICKET DATE',
    TICKETNUMBER = 'TICKET NUMBER',
    TYPE = 'TYPE',
    LEASEOPERATOR = 'CARRIER COMPANY',
    DRIVERNAME = 'DRIVER NAME',
    TRAILER = 'TRAILER',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    PRODUCT = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    RELATEDVOLUME = 'RELATED VOLUME TICKET',
    COMMENTS = 'COMMENTS',
    STATUS = 'STATUS',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    REGION = 'REGION'
}

export enum HourlyReportFieldName {
    TICKETDATE = 'Ticket_Date',
    TICKETNUMBER = 'Ticket_Number',
    TYPE = 'Type',
    LEASEOPERATOR = 'Lease_Operator',
    DRIVERNAME = 'Driver_Name',
    TRAILER = 'Trailer',
    CONSIGNOR = 'Consignor',
    CONSIGNEE = 'Consignee',
    PRODUCT = 'Product',
    VOLUMEDELIVERED = 'Volume_Delivered',
    WORKINGTIME = 'Working_Time',
    WORKINGTIMEREASON = 'Working_Time_Reason',
    WAITINGTIME = 'Waiting_Time',
    WAITINGTIMEREASON = 'Waiting_Time_Reason',
    RELATEDVOLUME = 'Related_Volume_Ticket',
    COMMENTS = 'Comments',
    STATUS = 'Status',
    APPROVERCOMMENT = 'Approver_Comment',
    REGION = 'Region'
}

export enum TitlePDF {
    DailyReport = 'Daily Report',
    LeaseOperator = 'Carrier Company Report',
    HourlyReport = 'Hourly Report',
    CustomerReport = 'Customer Report',
    RejectedLoads = 'Rejected Loads',
    ScheduledReports = 'Scheduled Reports',
    UpcomingCertificatesReport = 'Upcoming Certifications Report',
    MasterTicketReport = 'Master Ticket Report'
}
export enum SearchTicketHeaderName {
    SELECT = 'SELECT',
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    SHIPPERNAME = 'SHIPPER NAME',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    ROADBAN = 'ROAD BAN',
    VIA = 'ROAD VIA',
    LASTPTISTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'MODIFIED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'CARRIER COMPANY',
    DRIVERNAME = 'DRIVER NAME',
    CARRIERNAME = 'CARRIER NAME',
    TYPE = 'TYPE',
    LOADTYPE = 'LOAD TYPE',
    ACTUALVOL = 'ACTUAL VOLUME',
    ESTIMATEVOL = 'ESTIMATED VOLUME',
    PENDING = 'PENDING',
    DISPATCHED = 'DISPATCHED',
    COMPLETED = 'COMPLETED',
    STATUS = 'TICKET STATUS',
    TICKETNUMBER = 'TICKET NUMBER',
    MANUALTICKETNUMBER = 'MANUAL TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'MODIFIED DATE',
    TICKETDATE = 'TICKET DATE',
    TICKETCREATIONORPLANNEDDATE = 'TICKET CREATED DATE/TICKET PLANNED DATE',
    TICKETACCEPTEDDATE = 'TICKET ACCEPTED DATE',
    TRAILER = 'TRAILER',
    TRAILERNUMBER = 'TRAILER NUMBER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    BSW = 'BSW',
    DENSITY = 'DENSITY',
    GROSSWEIGHT = 'GROSS WEIGHT',
    TAREWEIGHT = 'TARE WEIGHT',
    NETWEIGHT = 'NET WEIGHT',
    METERFACTOR = 'METER FACTOR',
    METERNUMBER = 'METER NUMBER',
    OPENMETERREADING = 'OPEN METER READING',
    CLOSEMETERREADING = 'CLOSE METER READING',
    POWERUNITID = 'POWER UNIT IDENTIFIER',
    AXLES = 'AXLES',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    TRACTORMISMATCH = 'TRACTOR MAPPING MATCHED',
    POWERUNITIDENTIFIER = 'POWER UNIT IDENTIFIER',
    TRACTORMAPPEDTOCOMPANY = 'TRACTOR MAPPED TO COMPANY',
    TRACTORUSED = 'TRACTOR',
    COMPLETEDDATE = 'COMPLETED DATE TIME',
    LOADDATETIME = 'START/LOAD DATE TIME',
    ENDDATE = 'END DATE',
    REGION = 'REGION',
    TICKETSOURCE = 'TICKET SOURCE',
    CUSTOMERNAME = 'CUSTOMER NAME',
    LOADSTATUS = 'LOAD STATUS',
    PLANNEDDATEANDTIME = 'PLANNED DATE & TIME',
    DISPATCHEDDATE = 'DISPATCHED DATE',
    NOMINATIONMONTH = 'NOMINATION MONTH',
    NOMINATIONYEAR = 'NOMINATION YEAR',
    PRIORITY = 'PRIORITY',
    DISPATCHERCOMMENTS = 'DISPATCHER COMMENTS',
    DISPATCHERNOTES = "DISPATCHER NOTES",
    DRIVERCOMMENTS = 'DRIVER COMMENTS',
    DATASOURCE = 'DATA SOURCE',
    CREATEDBY = 'CREATED BY',
    CREATEDDATE = 'CREATED DATE',
    MODIFIEDBY = 'MODIFIED BY',
    MODIFIEDDATE = 'MODIFIED DATE'
}
export enum SearchTicketFieldName {
    ID = 'ID',
    PRODUCT = 'Product',
    SHIPPERTICKET = 'ShipperTicketBOL',
    ROADBAN = 'RoadBan',
    VIA = 'Via',
    LASTPMTSTATUS = 'LastPMTStatus',
    RELATEDVOLUMETICKET = 'RelatedVolumeTicket',
    WORKINGTIME = 'WorkingTime',
    COMMENT = 'Comments',
    SHIPPERNAME = 'ShipperName',
    LOADTYPE = 'LoadType',
    LEASEOPERATOR = 'LeaseOperatorName',
    DRIVERNAME = 'DriverName',
    CARRIERNAME = 'CarrierName',
    TYPE = 'TicketType',
    STATUS = 'TicketStatus',
    TICKETNUMBER = 'TicketNumber',
    LOADNUMBER = 'LoadNumber',
    MANUALTICKETNUMBER = 'ManualTicketNumber',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    ACTUALVOL = 'VolumeDelivered',
    ESTIMATEVOL = 'EstimatedVolume',
    PENDING = 'PendingVolume',
    DISPATCHED = 'DispatchedVolume',
    COMPLETED = 'CompletedVolume',
    WORKINGTIMEREASON = 'WorkingTimeReason',
    WAITINGTIMEREASON = 'WaitingTimeReason',
    WAITINGTIME = 'WaitingTime',
    VOLUMEDELIVERED = 'VolumeDelivered',
    TICKETDATE = 'TicketDate',
    TRAILERNUMBER = 'TrailerNumber',
    LOADSTATUS = 'LoadStatus',
    APPROVERCOMMENT = 'ApproverComment',
    BSW = 'BSW',
    DENSITY = 'Density',
    GROSSWEIGHT = 'GrossWeight',
    TAREWEIGHT = 'TareWeight',
    NETWEIGHT = 'NetWeight',
    METERFACTOR = 'MeterFactor',
    METERNUMBER = 'MeterNumber',
    OPENMETERREADING = 'OpenMeterReading',
    CLOSEMETERREADING = 'CloseMeterReading',
    POWERUNITID = 'PowerUnitIdentifier',
    AXLES = 'Axles',
    TRAILERSTATUS = 'TrailerStatus',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    TRACTORMAPPEDTOCOMPANY = 'TractorMappedToCompany',
    POWERUNITIDENTIFIER = 'PowerUnitIdentifier',
    COMPLETEDDATE = 'CompletedDate',
    LOADDATETIME = 'LoadDateTime',
    STARTDATE = 'StartDate',
    ENDDATE = 'EndDate',
    REGION = 'Region',
    PRIORITY = 'Priority',
    TICKETSOURCE = 'TicketSource',
    CUSTOMERNAME = 'CustomerName',
    PLANNEDDATEANDTIME = 'PlannedDateTime',
    DISPATCHEDDATE = 'DispatchedDate',
    NOMINATIONMONTH = 'NominationMonth',
    NOMINATIONYEAR = 'NominationYear',
    DISPATCHERCOMMENTS = 'DispatcherComments',
    DISPATCHERNOTES = "DispatcherNotes",
    DRIVERCOMMENTS = 'DriverComments',
    ISDELETEDDISPATCHEDLOAD ='IsDeletedDispatchedLoad',
    DATASOURCE ='DataSource',
    CREATEDBY = 'CreatedBy',
    CREATEDDATE = 'CreatedDate',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate',
    TICKETACCEPTEDDATE = 'AcceptedDate'
}
export enum TicketHeaderName {
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    ROADBAN = 'ROAD BAN',
    VIA = 'ROAD VIA',
    LASTPTISTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'LAST UPDATED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'LEASE OPERATOR',
    DRIVERNAME = 'DRIVER NAME',
    TYPE = 'TYPE',
    STATUS = 'STATUS',
    TICKETNUMBER = 'TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'UPDATED DATE',
    TICKETDATE = 'TICKET CREATED DATE/ TICKET PLANNED DATE',
    TICKETACCEPTEDDATE = 'TICKET ACCEPTED DATE',
    TRAILER = 'TRAILER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    COMPLETEDDATETIME = 'COMPLETED DATE TIME',
    LOADDATETIME = 'START/LOAD DATE TIME',
    REGION = 'REGION'
}

export enum TicketFieldName {
    PRODUCT = 'Product',
    SHIPPERTICKET = 'ShipperTicketBOL',
    ROADBAN = 'RoadBan',
    VIA = 'Via',
    RELATEDVOLUMETICKET = 'RelatedVolumeTicket',
    WORKINGTIME = 'WorkingTime',
    COMMENT = 'Comments',
    LEASEOPERATOR = 'LeaseOperatorName',
    DRIVERNAME = 'DriverName',
    TYPE = 'TicketType',
    TICKETNUMBER = 'TicketNumber',
    STATUS = 'TicketStatus',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    WORKINGTIMEREASON = 'WorkingTimeReason',
    WAITINGTIMEREASON = 'WaitingTimeReason',
    WAITINGTIME = 'WaitingTime',
    VOLUMEDELIVERED = 'VolumeDelivered',
    TICKETDATE = 'TicketDate',
    TRAILER = 'Trailer',
    ISAPPROVED = 'isApproved',
    APPROVERCOMMENT = 'ApproverComment',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    COMPLETEDDATETIME = 'CompletedDate',
    LOADDATETIME = 'LoadDATETIME',
    STARTDATETIME = 'StartDate',
    REGION = 'Region',
    TICKETACCEPTEDDATE = 'AcceptedDate'
}

export enum CustomerReportHeaderName {
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    POWERUNITID = 'POWER UNIT IDENTIFIER',
    AXLES = 'AXLES',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    ROADBAN = 'ROAD BAN',
    VIA = 'ROAD VIA',
    LASTPTISTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'LAST UPDATED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'CARRIER COMPANY',
    DRIVERNAME = 'DRIVER NAME',
    TYPE = 'TYPE',
    STATUS = 'STATUS',
    TICKETNUMBER = 'TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'UPDATED DATE',
    TICKETDATE = 'TICKET CREATED DATE/ TICKET PLANNED DATE',
    TICKETACCEPTEDDATE = 'TICKET ACCEPTED DATE',
    TICKETTIME = 'TICKET TIME',
    TRAILER = 'TRAILER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    DENSITY = 'DENSITY',
    BSW = 'BSW',
    LOADDATE = 'LOAD DATE',
    LOADTIME = 'LOAD TIME',
    GROSSWEIGHT = 'GROSS WEIGHT',
    TAREWEIGHT = 'TARE WEIGHT',
    NETWEIGHT = 'NET WEIGHT',
    METERNUMBER = 'METER NUMBER',
    OPENMETERREADING = 'OPEN METER READING',
    CLOSEMETERREADING = 'CLOSE METER READING',
    METERFACTOR = 'METER FACTOR',
    RUNTICKET = 'RUN TICKET/SCALE TICKET',
    STARTDATE = 'START DATE',
    STARTTIME = 'START TIME',
    ENDDATE = 'END DATE',
    UNITS = 'UNITS',
    CONSIGNERADDRESS = 'CONSIGNOR ADDRESS',
    CONSIGNEORNUMBER = 'CONSIGNOR ERP PHONENUMBER',
    CONSIGNORERPPLANNUMBER = 'CONSIGNOR ERP PLAN NUMBER',
    CREATEDBY = 'CREATED BY',
    AUTHORIZEDBY = 'AUTHORIZED BY',
    COMPLETEDDATE = 'COMPLETED DATE',
    COMPLETEDTIME = 'COMPLETED TIME',
    REGION = 'REGION',
    DISPATCHERCOMMENTS = 'DISPATCHER COMMENTS'
}

export enum CustomerReportFieldName {
    PRODUCT = 'Product',
    SHIPPERTICKET = 'ShipperTicketBOL',
    POWERUNITID = 'PowerUnitIdentifier',
    AXLES = 'Axles',
    ROADBAN = 'RoadBan',
    VIA = 'Via',
    RELATEDVOLUMETICKET = 'RelatedVolumeTicket',
    WORKINGTIME = 'WorkingTime',
    COMMENT = 'Comments',
    LEASEOPERATOR = 'LeaseOperatorName',
    DRIVERNAME = 'DriverName',
    TYPE = 'TicketType',
    TICKETNUMBER = 'TicketNumber',
    STATUS = 'TicketStatus',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    WORKINGTIMEREASON = 'WorkingTimeReason',
    WAITINGTIMEREASON = 'WaitingTimeReason',
    WAITINGTIME = 'WaitingTime',
    VOLUMEDELIVERED = 'VolumeDelivered',
    TICKETDATE = 'TicketCreationDate',
    TICKETTIME = 'TicketTime',
    TRAILER = 'Trailer',
    ISAPPROVED = 'isApproved',
    APPROVERCOMMENT = 'ApproverComment',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    DENSITY = 'Density',
    BSW = 'BSW',
    LOADDATETIME = 'LoadDateTime',
    LOADDATE = 'LoadDate',
    LOADTIME = 'LoadTime',
    GROSSWEIGHT = 'GrossWeight',
    TAREWEIGHT = 'TareWeight',
    NETWEIGHT = 'NetWeight',
    METERNUMBER = 'MeterNumber',
    OPENMETERREADING = 'OpenMeterReading',
    CLOSEMETERREADING = 'CloseMeterReading',
    METERFACTOR = 'MeterFactor',
    RUNTICKET = 'RunTickeScaleTicketNumber',
    STARTDATE = 'StartDate',
    STARTTIME = 'StartTime',
    ENDDATE = 'EndDate',
    UNITS = 'UomID',
    CONSIGNERADDRESS = 'ConsignorAddress',
    CONSIGNEORNUMBER = 'ConsignorERPPhoneNumber',
    CONSIGNORERPPLANNUMBER = 'ConsignorERPPlanNumber',
    CREATEDBY = 'CreatedBy',
    AUTHORIZEDBY = 'AuthorizedBy',
    LASTPMTSTATUS = 'LastPMTStatus',
    COMPLETEDDATE = 'CompletedDate',
    COMPLETEDTIME = 'CompletedTime',
    UPDATEDDATE = 'UpdatedDate',
    TICKETDATETIME = 'TicketDate',
    STARTDATETIME = 'StartDateTime',
    COMPLETEDDATETIME = 'CompletedDateTime',
    REGION = 'Region',
    TICKETACCEPTEDDATE = 'AcceptedDate',
    DISPATCHERCOMMENTS = 'DispatcherComments'
}
export enum RejectedLoadHeaderName {
    TICKETNUMBER = 'TICKET NUMBER',
    STATUS = 'STATUS',
    TICKETDATE = 'TICKET DATE',
    CONSIGNEE = 'CONSIGNEE',
    CONSIGNOR = 'CONSIGNOR',
    DRIVERNAME = 'DRIVER NAME',
    CARRIERNAME = 'CARRIER COMPANY',
    TRAILERNUMBER = 'TRAILERNUMBER',
    COMMENT = 'COMMENTS'
}
export enum RejectedLoadFieldName {
    TICKETNUMBER = 'TicketNumber',
    STATUS = 'LoadStatus',
    TICKETDATE = 'TicketDate',
    CONSIGNEE = 'Consignee',
    CONSIGNOR = 'Consignor',
    DRIVERNAME = 'DriverName',
    CARRIERNAME = 'CarrierCompany',
    TRAILERNUMBER = 'TrailerNumber',
    COMMENT = 'Comments'
}

export enum ScheduledReportHeaderName {
    ID = 'REPORT ID',
    REPORTYPE = 'REPORT TYPE',
    CREATEDBY = 'CREATED BY',
    CREATEDDATE = 'CREATED DATE',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    MODIFIEDBY = 'MODIFIED BY',
    MODIFIEDDATE = 'MODIFIED DATE',
    PAUSESTOP = 'STOP/PAUSE'
}
export enum ScheduledReportFieldName {
    ID = 'ID',
    REPORTYPE = 'Frequency',
    CREATEDBY = 'CreatedBy',
    CREATEDDATE = 'CreatedDate',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate',
    CONSIGNOR = 'ConsignorName',
    CONSIGNEE = 'ConsigneeName',
    PAUSESTOP = 'IsPaused'
}

export enum UpcomingCertificatesHeaderName {
    DATE = 'REPORT DATE',
    TRAILERNUMBER = 'TRAILER NUMBER',
    MAPPEDCARRIERCOMPANY = 'MAPPED CARRIER COMPANY',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERTYPE = 'TRAILER TYPE',
    DUEINSPECTIONSCOUNT = 'INSPECTIONS COMING DUE COUNT',
    DUEDATE = 'DUE DATE',
    DUEINSPECTIONS = 'INSPECTIONS COMING DUE'
}

export enum UpcomingCertificatesFieldName {
    DATE = 'Date',
    TRAILERNUMBER = 'TrailerNumber',
    MAPPEDCARRIERCOMPANY = 'CompanyName',
    TRAILERSTATUS = 'TrailerStatus',
    TRAILERTYPE = 'TrailerType',
    DUEINSPECTIONSCOUNT = 'CertificateCount',
    DUEINSPECTIONS = 'DueCertificates',
    DUEDATE = 'DueDate'
}

export enum MasterTicketReportHeaderName {
    SELECT = 'SELECT',
    TICKETDATE = 'TICKET CREATED DATE/ TICKET PLANNED DATE',
    TICKETACCEPTEDDATE = 'TICKET ACCEPTED DATE',
    TICKETNUMBER = 'TICKET NUMBER',
    TYPE = 'TICKET TYPE',
    DRIVERNAME = 'DRIVER NAME',
    POWERUNIT = 'POWER UNIT IDENTIFIER',
    PRODUCT = 'PRODUCT',
    CONSIGNOR = 'CONSIGNOR',
    CONSIGNEE = 'CONSIGNEE',
    ACTUALVOL = 'ACTUAL VOLUME',
    ESTIMATEVOL = 'ESTIMATED VOLUME',
    STATUS = 'STATUS',
    LOADDATE = 'LOAD DATE',
    CARRIER = 'CARRIER COMPANY',
    TRAILER = 'TRAILER',
    COMPLETEDDATETIME = 'COMPLETED DATE TIME',
    LOADDATETIME = 'START/ LOAD DATE TIME',
    REGION = 'REGION',
    Id = 'ID',
    Product = 'PRODUCT',
    VOLUMEDELIVERED = 'VOLUME DELIVERED',
    SHIPPERNAME = 'SHIPPER NAME',
    SHIPPERTICKET = 'SHIPPER TICKET / BOL',
    ROADBAN = 'ROAD BAN',
    VIA = 'ROAD VIA',
    LASTPMTSTATUS = 'LAST PMT STATUS',
    RELATEDVOLTICKET = 'RELATED VOLUME TICKET',
    LASTUPDATEDBY = 'MODIFIED BY',
    COMMENT = 'COMMENTS',
    LEASEOPERATOR = 'CARRIER COMPANY',
    LOADTYPE = 'LOAD TYPE',
    PENDING = 'PENDING',
    DISPATCHED = 'DISPATCHED',
    COMPLETED = 'COMPLETED',
    MANUALTICKETNUMBER = 'MANUAL TICKET NUMBER',
    ORIGIN = 'CONSIGNOR',
    DESTINATION = 'CONSIGNEE',
    WORKINGTIME = 'WORKING TIME',
    WORKINGTIMEREASON = 'WORKING TIME REASON',
    WAITINGTIME = 'WAITING TIME',
    WAITINGTIMEREASON = 'WAITING TIME REASON',
    UPDATEDDATE = 'MODIFIED DATE',
    TRAILERNUMBER = 'TRAILER NUMBER',
    APPROVERCOMMENT = 'APPROVER COMMENT',
    BSW = 'BSW',
    DENSITY = 'DENSITY',
    GROSSWEIGHT = 'GROSS WEIGHT',
    TAREWEIGHT = 'TARE WEIGHT',
    NETWEIGHT = 'NET WEIGHT',
    METERFACTOR = 'METER FACTOR',
    METERNUMBER = 'METER NUMBER',
    OPENMETERREADING = 'OPEN METER READING',
    CLOSEMETERREADING = 'CLOSE METER READING',
    POWERUNITID = 'POWER UNIT IDENTIFIER',
    AXLES = 'AXLES',
    LASTPDSSTATUS = 'LAST PDS STATUS',
    LASTTICKETSTATUS = 'LAST TICKET STATUS',
    TRAILERSTATUS = 'TRAILER STATUS',
    TRAILERMAPPEDTOCOMPANY = 'TRAILER MAPPED TO COMPANY',
    MISMATCH = 'TRAILER MAPPING MATCHED',
    TRACTORMISMATCH = 'TRACTOR MAPPING MATCHED',
    POWERUNITIDENTIFIER = 'POWER UNIT IDENTIFIER',
    TRACTORMAPPEDTOCOMPANY = 'TRACTOR MAPPED TO COMPANY',
    TRACTORUSED = 'TRACTOR',
    COMPLETEDDATE = 'COMPLETED DATE TIME',
    ENDDATE = 'END DATE',
    TICKETSOURCE = 'TICKET SOURCE',
    CUSTOMERNAME = 'CUSTOMER NAME',
    LOADSTATUS = 'LOAD STATUS',
    PLANNEDDATEANDTIME = 'PLANNED DATE & TIME',
    DISPATCHEDDATE = 'DISPATCHED DATE',
    NOMINATIONMONTH = 'NOMINATION MONTH',
    NOMINATIONYEAR = 'NOMINATION YEAR',
    PRIORITY = 'PRIORITY',
    DISPATCHERCOMMENTS = 'DISPATCHER COMMENTS',
    DISPATCHERNOTES = "DISPATCHER NOTES",
    DRIVERCOMMENTS = 'DRIVER COMMENTS',
    DATASOURCE = 'DATA SOURCE',
    CREATEDBY = 'CREATED BY',
    CREATEDDATE = 'CREATED DATE',
    MODIFIEDBY = 'MODIFIED BY',
    MODIFIEDDATE = 'MODIFIED DATE'
}

export enum MasterTicketReportFieldName {
    TICKETDATE = 'TicketDate',
    TICKETNUMBER = 'TicketNumber',
    TYPE = 'TicketType',
    DRIVERNAME = 'DriverName',
    POWERUNIT = 'PowerUnitIdentifier',
    PRODUCT = 'Product',
    CONSIGNOR = 'Origin',
    CONSIGNEE = 'Destination',
    ACTUALVOL = 'VolumeDelivered',
    ESTIMATEVOL = 'EstimatedVolume',
    STATUS = 'TicketStatus',
    STARTDATE = 'StartDate',
    CARRIER = 'LeaseOperatorName',
    TRAILER = 'Trailer',
    COMPLETEDDATETIME = 'CompletedDateTime',
    LOADDATETIME = 'LoadDateTime',
    REGION = 'Region',
    ID = 'ID',
    SHIPPERTICKET = 'ShipperTicketBOL',
    ROADBAN = 'RoadBan',
    VIA = 'Via',
    LASTPMTSTATUS = 'LastPMTStatus',
    RELATEDVOLUMETICKET = 'RelatedVolumeTicket',
    WORKINGTIME = 'WorkingTime',
    COMMENT = 'Comments',
    SHIPPERNAME = 'ShipperName',
    LOADTYPE = 'LoadType',
    LEASEOPERATOR = 'LeaseOperatorName',
    LOADNUMBER = 'LoadNumber',
    MANUALTICKETNUMBER = 'ManualTicketNumber',
    ORIGIN = 'Origin',
    DESTINATION = 'Destination',
    PENDING = 'PendingVolume',
    DISPATCHED = 'DispatchedVolume',
    COMPLETED = 'CompletedVolume',
    WORKINGTIMEREASON = 'WorkingTimeReason',
    WAITINGTIMEREASON = 'WaitingTimeReason',
    WAITINGTIME = 'WaitingTime',
    VOLUMEDELIVERED = 'VolumeDelivered',
    TRAILERNUMBER = 'TrailerNumber',
    LOADSTATUS = 'LoadStatus',
    APPROVERCOMMENT = 'ApproverComment',
    BSW = 'BSW',
    DENSITY = 'Density',
    GROSSWEIGHT = 'GrossWeight',
    TAREWEIGHT = 'TareWeight',
    NETWEIGHT = 'NetWeight',
    METERFACTOR = 'MeterFactor',
    METERNUMBER = 'MeterNumber',
    OPENMETERREADING = 'OpenMeterReading',
    CLOSEMETERREADING = 'CloseMeterReading',
    POWERUNITID = 'PowerUnitIdentifier',
    AXLES = 'Axles',
    TRAILERSTATUS = 'TrailerStatus',
    TRAILERMAPPEDTOCOMPANY = 'TrailerMappedToCompany',
    TRACTORMAPPEDTOCOMPANY = 'TractorMappedToCompany',
    POWERUNITIDENTIFIER = 'PowerUnitIdentifier',
    COMPLETEDDATE = 'CompletedDate',
    ENDDATE = 'EndDate',
    PRIORITY = 'Priority',
    TICKETSOURCE = 'TicketSource',
    CUSTOMERNAME = 'CustomerName',
    PLANNEDDATEANDTIME = 'PlannedDateTime',
    DISPATCHEDDATE = 'DispatchedDate',
    NOMINATIONMONTH = 'NominationMonth',
    NOMINATIONYEAR = 'NominationYear',
    DISPATCHERCOMMENTS = 'DispatcherComments',
    DISPATCHERNOTES = "DispatcherNotes",
    DRIVERCOMMENTS = 'DriverComments',
    ISDELETEDDISPATCHEDLOAD ='IsDeletedDispatchedLoad',
    DATASOURCE ='DataSource',
    CREATEDBY = 'CreatedBy',
    CREATEDDATE = 'CreatedDate',
    MODIFIEDBY = 'ModifiedBy',
    MODIFIEDDATE = 'ModifiedDate',
    TICKETACCEPTEDDATE = 'AcceptedDate'
}