import React, { Component } from 'react';
import { GridOptions, FilterChangedEvent } from 'ag-grid-community';
import crypto from 'crypto';
import Select, { components, ActionMeta, ValueType } from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import { reportticketStatus } from '../reports_constants';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import * as XLSX from 'xlsx';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import { ConfirmProps, CustomerReportScheduleEmailPopup } from './customerReportSchedulePopup';
import * as FileSaver from 'file-saver';
import { ScheduledReportDataStore } from '../stores/scheduledreport-data-store';
import { customerReportStore } from '../stores';
import { scheduledReportDataService } from '../services/scheduledreport-data-service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

interface ScheduledReportProps {
    // customerReportStore: CustomerReportStore;
     scheduledReportStore: ScheduledReportDataStore;
}

@observer
export class ScheduledReportComponent extends Component<ScheduledReportProps> {
    startDate: any;
    endDate: any;
    from: any = moment()
        .subtract(6, 'days')
        .toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    selectedOption: any = [{ value: 'all', label: 'All' }];
    selectLeaseOperator: any = [];
    selectConsignee: any = [];
    constructor(props) {
        super(props);
        this.state = { actVolSum: 0, actVolAvg: 0 };
        dataStore.setModuleName('ScheduledReports');
    }

    componentDidMount() {
        const { scheduledReportStore } = this.props;
        scheduledReportStore.init();
    }
    // getTickets = () => {
    //     const { customerReportStore } = this.props;
    //     customerReportStore.getRangeResult(
    //         this.startDate.toISOString().slice(0, 10),
    //         this.endDate.toISOString().slice(0, 10)
    //     );
    // };

    componentWillUnmount() {
        const { scheduledReportStore } = this.props;
        scheduledReportStore.isRadioButtonEnabled = false;
    }

    render(): React.ReactNode {
        // const {
        //     customerReportStore: { isDisabled }
        // } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    <ClearButtonComponent
                                        handleRefresh={this.handleRefresh}
                                        disabled={this.props.scheduledReportStore.isDisabled()}
                                        isExportCSV={true}
                                    ></ClearButtonComponent>
                                    {dataStore.checkOperationAccess('Save') &&
                                    <>
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.viewReport}
                                        disabled={
                                            !this.props.scheduledReportStore.isRadioButtonEnabled
                                        }
                                    >
                                        View Report Details
                                    </CustomButton>
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.deleteReport}
                                        disabled={
                                            !this.props.scheduledReportStore.isRadioButtonEnabled
                                        }
                                    >
                                        Delete
                                    </CustomButton>
                                    </>}
                                </Col>
                            </Row>
                        </div>
                        {/* <div>
                            <ReactDayPickerInput _dataStore={this.props.scheduledReportStore}></ReactDayPickerInput>
                        </div> */}
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('ScheduledReport_filter');
        this.props.scheduledReportStore.agGridService.setAllFilters(filters);
    }

    deleteReport = async () => {
        const { scheduledReportStore } = this.props;
        console.log(scheduledReportStore.selectedData);
        const reqbody: any = {};
        reqbody.ID = scheduledReportStore.selectedData.ID;
        reqbody.IsDeleted = true;
        let userID = this.props.scheduledReportStore.userID;
        if (!userID || userID === 0 ) {
            await this.props.scheduledReportStore.accountStore.getLoggedInUserDetailsIfUserIdZero(this.props.scheduledReportStore.accountStore.userName).then(() => {
                this.props.scheduledReportStore.userID = this.props.scheduledReportStore.accountStore.getUserID();
                userID = this.props.scheduledReportStore.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        reqbody.CreatedBy = userID;
        await scheduledReportDataService.createCustomerReport(reqbody);
        scheduledReportStore.init();
    }

    private viewReport = async (): Promise<void> => {
        const { scheduledReportStore } = this.props;
        const selectedLeaseOperator = this.selectLeaseOperator.map((item: any) => item.value);
        const reqbody = {
            ConsignorName: this.props.scheduledReportStore.selectedData.ConsignorName
        };
        // await scheduledReportStore.getEmailConfiguration(reqbody);

        this.sendScheduledData();
    };
    private sendScheduledData() {
        const { scheduledReportStore } = this.props;
        const fields = [
            'Product',
            'ShipperTicketBOL',
            'RoadBan',
            'Via',
            'LastPMTStatus',
            'RelatedVolumeTicket',
            'WorkingTime',
            'ApproverComment',
            'LeaseOperatorName',
            'DriverName',
            'TicketType',
            'TicketStatus',
            'TicketNumber',
            'Origin',
            'Destination',
            'VolumeDelivered',
            'WorkingTimeReason',
            'TicketCreationDate',
            'Trailer',
            'DispatcherComments',
            'UpdatedBy',
            'WaitingTime',
            'WaitingTimeReason',
            'UpdatedDate',
            'UomID',
            'ConsignorName',
            'ConsignorAddress',
            'PowerUnitIdentifier',
            'StartDate',
            'EndDate',
            'EstimatedVolume',
            'ConsignorERPPhoneNumber',
            'RunTickeScaleTicketNumber',
            'LoadDate',
            'LoadTime',
            'GrossWeight',
            'TareWeight',
            'NetWeight',
            'Density',
            'BSW',
            'OpenMeterReading',
            'CloseMeterReading',
            'MeterNumber',
            'MeterFactor',
            'CreatedBy',
            'AuthorizedBy',
            'Axles',
            'CompletedDate',
            'StartDate',
            'StartTime',
            'CompletedTime',
            'TicketTime'
        ];
        const status = [
            'New',
            'Approved',
            'Not Approved',
            'Hold',
            'Cancelled',
            'Completed'
        ];
        const confirmService = new ConfirmService();
        const fileName = 'customerReport' + new Date().getTime() + '.xlsx';
        const currentDate = moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss');
        const selectedStartDate = scheduledReportStore.startDate;
        const selectedEndDate = scheduledReportStore.endDate;
        const selectedConsignee = this.props.scheduledReportStore.selectedData.ConsigneeName != null ? this.props.scheduledReportStore.selectedData.ConsigneeName.split(",").map(consignee =>  ({ value: consignee, label: consignee })) : []
        const selectedConsignor = this.props.scheduledReportStore.selectedData.ConsignorName != null ? this.props.scheduledReportStore.selectedData.ConsignorName.split(",").map(consignor => ({ value: consignor, label: consignor })) : []

        confirmService.showConfirmWithCustomComponent(
            async data => {
                const reqbody = data;
                let userID = this.props.scheduledReportStore.accountStore.getUserID();
                if (!userID || userID === 0 ) {
                    await this.props.scheduledReportStore.accountStore.getLoggedInUserDetailsIfUserIdZero(this.props.scheduledReportStore.accountStore.userName).then(() => {
                        userID = this.props.scheduledReportStore.accountStore.getUserID();
                    });
                    if (!userID || userID === 0 ) {
                        return;
                    }
                }
                reqbody.CreatedBy = userID;
                reqbody.ModifiedBy = userID;
                reqbody.ScreenName = fileName;
                if (data.Frequency === 'Adhoc') {
                    reqbody.StartDate = selectedStartDate;
                    reqbody.EndDate = selectedEndDate;
                }
                
                await this.props.scheduledReportStore.createCustomerReport(reqbody);
                scheduledReportStore.init();
            },
            CustomerReportScheduleEmailPopup,
            {
                status: status,
                fields: fields,
                selectedLeaseOperator: selectedConsignor,
                selectedConsignee: selectedConsignee,
                emailConfiguration: [this.props.scheduledReportStore.selectedData],
                locationDropdown: this.props.scheduledReportStore.operatorName,
                module: 'ScheduleReport'
            } as ConfirmProps,
            ''
        );
    }

    handleRefresh = () => {
        const { scheduledReportStore } = this.props;
        if(Object.keys(scheduledReportStore.selectedData).length === 0) {
            scheduledReportStore.init();
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    scheduledReportStore.init();
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
        scheduledReportStore.selectedData = {}
    }

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.scheduledReportStore.agGridService.getNodes();
        localStorageService.set(
            'ScheduledReport_filter',
            this.props.scheduledReportStore.agGridService.getAllFilters()
        );
    };

    private getGridConfig(): GridOptions {
        const {
            scheduledReportStore: { ScheduledReport, agGridService }
        } = this.props;
        const { scheduledReportStore } = this.props;

        const rowData = ScheduledReport;
        const columnDefs = scheduledReportStore.getColDef();
        const onFilterChanged = this.onFilterChanged;
        const ScheduledReportGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(ScheduledReportGridParams);
    }
}
